<template>
  <div class="swiper-container mainSlide">
    <div class="swiper-wrapper">
      <!--div class="swiper-slide" v-for="item in banner" v-bind:key="item.bannerIdx">
        <a :href="item.domain">
          <img :src="item.bannerImg" alt="slide">
        </a>
      </div-->
      <div class="swiper-slide">
        <div class="slide slidea">
          <img src="../../assets/img/buja/banner-char-01.png">
          <div class="slideTxt">
            <h5 class="text-yellow">게시판 이벤트 참고</h5>
            <h3>이벤트 타이틀 입력</h3>
            <h4>이벤트내용을 잘 적어주세요</h4>
            <button>내용더보기</button>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="slide slideb">
          <img src="../../assets/img/buja/banner-char-02.png">
          <div class="slideTxt">
            <h5 class="text-yellow">게시판 이벤트 참고</h5>
            <h3>이벤트 타이틀 입력</h3>
            <h4>이벤트내용을 잘 적어주세요</h4>
            <button>내용더보기</button>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="slide slidec">
          <img src="../../assets/img/buja/banner-char-03.png">
          <div class="slideTxt">
            <h5 class="text-yellow">게시판 이벤트 참고</h5>
            <h3>이벤트 타이틀 입력</h3>
            <h4>이벤트내용을 잘 적어주세요</h4>
            <button>내용더보기</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-button-prev" role="button"></div>
    <div class="swiper-button-next" role="button"></div>
    <div class="swiper-pagination"></div> -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'UiSwiper',
  title: 'Navigation',
  data () {
    return {
      bannerList: [
      ]
    }
  },
  mounted () {
    this.loadSwiper()
  },
  watch: {
    banner () {
      if (this.banner) {
        this.loadSwiper()
      }
    }
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  methods: {
    loadSwiper () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container', {
        // spaceBetween: 30,
        autoplay: {
          delay: 6000, // 시간 설정
          disableOnInteraction: false // false로 설정하면 스와이프 후 자동 재생이 비활성화 되지 않음
        },
        loop: true,
        observer: true,
        observeParents: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        // pagination: {
        // clickable: true,
        // el: '.swiper-pagination'
        // }
        pagination: {
          el: '.swiper-pagination'
          // clickable: true,
          // renderBullet: function (index, className) {
          //   const title = list[index].bannerTitle || ''
          //   return '<div class="' + className + '"><span>' + title + '</span></div>'
          // }
        },
        on: {
          slideChange: function () {
            // Remove animation class from all elements
            document.querySelectorAll('.slide').forEach(function (element) {
              element.classList.remove('animate')
            })

            const currentSlide = this.slides[this.activeIndex]
            const animatedElements = currentSlide.querySelectorAll('.slide')
            animatedElements.forEach(function (element) {
              element.classList.add('animate')
            })
          }
        }
      })

      if (this.banner) {
        setTimeout(function () {
          this.$emit('loaded', true)
        }.bind(this), 500)
      }
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
.mainSlide {background: #000;}
.mainSlide > img {width: 100%;}
.swiper-container {z-index: 20; height: 100%;}
.swiper-slide {display: flex;align-items: center;justify-content: center; height: 100%;}
.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.slide img{
  width: 50%;
  margin-left: 10%;
}
.slide.animate {
  animation: banani 1s ease 1 forwards;
}
.slide.animate img{
  animation: bananiImg 1s ease 1 forwards;
}
.slide .slideTxt {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.slide .slideTxt h3, .slide .slideTxt h4 {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
}
.slide .slideTxt button {
  color: #ffffff;
  font-size: 14px;
  border-radius: 26px;
  border: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #cec070;
  background-image: linear-gradient(#cec070, #927100);
  width: 200px;
  height: 40px;
}
.slide .slideTxt button:hover:before {
    background-color: #44310e;
}
.slide .slideTxt button::after {
  content: '';
  width: calc(100% - 2px);
    height: calc(100% - 2px);
  border-radius: 28px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  background-color: #000000;
  background-image: none;
  opacity: 1.0;
}
.slide .slideTxt button:hover {
  background-color: #524b7f;
  border-color: #786dbd;
}
.slide.animate .slideTxt {
  animation: bigEntrance 5s ease 0.3s 1 backwards;
}
.slidea {
  background-image: url(../../assets/img/buja/banner-bg-01.jpg);
}
.slideb {
  background-image: url(../../assets/img/buja/banner-bg-02.jpg);
}
.slidec {
  background-image: url(../../assets/img/buja/banner-bg-03.jpg);
}
@keyframes banani {
  0% {
    background-size: 110%;
    filter: blur(5px);
  }
  100% {
    background-size: 100%;
    filter: blur(0px);
  }
}
@keyframes bananiImg {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bigEntrance {
    0% {transform: scale(0) rotate(10deg) translateX(-30%) translateY(30%);opacity: 0.2;}
    15% {transform: scale(1.03) rotate(-5deg) translateX(2%) translateY(-2%);opacity: 1;}
    20% {transform: scale(0.98) rotate(3deg) translateX(0%) translateY(0%);opacity: 1;}
    25% {transform: scale(1.01) rotate(-2deg) translateX(0%) translateY(0%);opacity: 1;}
    30% {transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);opacity: 1;}
    35% {transform: scale(1.05) rotate(0deg) translateX(0%) translateY(0%);opacity: 1;}
    45% {transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);opacity: 1;}
    100% {transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);opacity: 1;}
}
.text-yellow {
  color: #fae481 !important;
}
@media (max-width: 1000px) {
   .slide {justify-content: center;background-size: cover;}
   .slide img {width: 60%;margin-left: 0;height: 100%;object-fit: contain;object-position: bottom;}
   .slide .slideTxt {gap: 5px;width: 40%;}
   .slide .slideTxt h5 {font-size: 11px;margin-bottom: 5px;}
   .slide .slideTxt h3, .slide .slideTxt h4 {font-size: 11px;}
   .slide .slideTxt button {font-size: 11px;width: 100px;height: 20px;}
}
</style>
