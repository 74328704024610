<template>
  <div className="digital-counter"> <!--v-if="jackpot"-->
    <span class="won">KRW</span>
    <span>{{ formatNumber('75404788' + jackpot) }}</span>
    <span className="decimal">.</span>
    <span className="random-digits">
      <span>{{ randomDigit1 }}</span>
      <span>{{ randomDigit2 }}</span>
    </span>
  </div>
</template>

<script>
import { getJackpot } from '@/api/main'

export default {
  data () {
    return {
      jackpot: 123456789,
      randomDigit1: '0',
      randomDigit2: '0',
      intervalId: null
    }
  },
  async created () {
    await this.getJackpotData()
  },
  methods: {
    async getJackpotData () {
      await getJackpot({}).then(res => {
        const result = res.data

        if (result.resultCode === '0') {
          this.jackpot = Math.floor(Number(result.data.jackpot))
        }
      })
    },
    // A를 1초에 1 씩 증가시키는 메서드
    incrementA () {
      this.jackpot += 1
    },
    // 랜덤한 두 자리 숫자를 설정하는 메서드
    setRandomDigits () {
      this.randomDigit1 = Math.floor(Math.random() * 10).toString()
      this.randomDigit2 = Math.floor(Math.random() * 10).toString()
    },
    // odometer 애니메이션 효과를 위해 숫자를 자릿수에 맞게 포맷하는 메서드
    formatNumber (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    // 숫자 변경 시 애니메이션 효과 적용
    applyAnimation () {
      const digitsElement = document.querySelector('.random-digits')
      digitsElement.classList.remove('animated')

      // Reflow를 유발하여 애니메이션 재생을 위해 setTimeout 사용
      setTimeout(() => {
        digitsElement.classList.add('animated')
      }, 10)
    }
  },
  watch: {
    jackpot () {
      if (this.jackpot) {
        this.applyAnimation()
      }
    }
  },
  mounted () {
    // 초기 숫자 설정
    this.jackpot = 12345678
    this.setRandomDigits()

    // A를 1초에 1 씩 증가시키는 인터벌 시작
    this.intervalId = setInterval(() => {
      this.incrementA()
    }, 1500)

    // 랜덤한 두 자리 숫자를 0.2초마다 설정하는 인터벌 시작
    this.intervalId = setInterval(() => {
      this.setRandomDigits()
    }, 200)
  },
  beforeUnmount () {
    // 컴포넌트가 파괴되기 전에 인터벌 정리
    clearInterval(this.intervalId)
  }
}
</script>

<style>
.w230{ width: 238px;}
.digital-counter {
  font-size: 50px !important;
  font-weight: bold;
}

.digital-counter em {
  margin-right: 20px;
  color: #fff;
}

.digital-counter span {
  /* background: linear-gradient(to bottom, #b0772a, #ddb763 16%, #f4d780 25%, #f4d780 46%, #fbf9d8 70%, #f4d780 75%, #b0772a); */
  background: #fff;
  color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
  font-weight: bold;
}
.digital-counter span.won{
  margin-right: 15px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-linear-gradient(#d7d7d7, #ffffff, #c0c0c0);
  background-size: 100% 100%;
  font-size: 45px;
}

.random-digits {
  display: inline-block;
  position: relative;
}

.random-digits span {
  opacity: 1;
  transition: opacity 1s ease;
  width: 28px;
  display: inline-block;
}

.random-digits span.animated {
  animation: randomAnimation 1s linear;
}

@keyframes randomAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@media (max-width: 500px) {
  .random-digits span {width: 15px;}
  .digital-counter span {font-size: 25px;}
  .digital-counter span.won{margin-right: 10px;font-size: 20px;}
}
</style>
